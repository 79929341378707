import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { Helmet } from 'react-helmet';
import HubspotForm from '@components/hubspot'
import { FaTwitter, FaFacebookF, FaLinkedin, FaLinkedinIn } from "react-icons/fa";

function Footer() {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            description
          }
        }
      }
    `
  );

  return (
    <footer className="ar-footer section">
      <div className="container">
        <div className="columns">
          <div className="column is-4 is-4-widescreen">
            <div className="summary">
              <Link to="/" className="brand">
                <div className="brand-icon">
                  <img
                    alt="Hackrate logo"
                    role="presentation"
                    src="/img/icon.png"
                  />
                </div>
                <div className="brand-content">
                  <div className="brand-title"> Hackrate Blog </div>
                 {/*  <div className="brand-subtitle">Full Stack Dev</div> */}
                </div>
              </Link>
           
              <p> {site.siteMetadata.description}</p>

            </div>
          </div>
          <div className="column is-8 is-6-widescreen is-offset-1-widescreen">
            <div className="columns">
              <div className="column is-4">
                <div className="menu">
                  <p className="menu-label"> Navigation </p>
                  <ul className="menu-list">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                    <Link to="/about/">About us</Link>
                    </li>
                    <li>
                      <Link to="/blog/">Blog</Link>
                    </li>
                   {/*  <li>
                      <Link to="/snippets/">Snippets</Link>
                    </li> */}
                    <li>
                      <Link to="/topics/">Topics</Link>
                    </li>
                    {/* <li>
                      <Link to="/contact/">Contact</Link>
                    </li> */}
                    <li>
                    <OutboundLink
                        target="_blank"
                        href={`https://www.hckrt.com/Home/PrivacyPolicy`}
                      >
                        Privacy Policy
                      </OutboundLink>
                    </li>
                    <li>
                    <OutboundLink
                        target="_blank"
                        href={`https://www.hckrt.com`}
                      >
                        Go to the Portal
                      </OutboundLink>
                      </li>
                      <li>
                    <OutboundLink
                        target="_blank"
                        href={`https://hackgate.io`}
                      >
                        HackGATE
                      </OutboundLink>
                      </li>
                  </ul>
                </div>
              </div>
              <div className="column is-5">


              <HubspotForm>
              </HubspotForm>
                {/* <div className="menu">
                  <p className="menu-label">Recommended</p>
                  <ul className="menu-list">
                    <li>
                      <Link to="/recommended/stories/">Developer Stories</Link>
                    </li>
                    <li>
                      <Link to="/recommended/services/">Tools & Services</Link>
                    </li>
                    <li>
                      <Link to="/recommended/videos/">Videos</Link>
                    </li>
                  </ul>
                </div> */}
              </div>
              <div className="column is-5">
                <div className="menu">
                  <p className="menu-label">Connect with us</p>
                  <ul className="menu-list">
                  <li>
                  <a
              href="https://linkedin.com/company/hackrate"
              target="_blank"
            >
              <div className="is-vertical-center">
                <span className="icon">  <FaLinkedinIn size={18} color= {"#FFFFFF"}  /> </span>
                <span className="has-margin"> LinkedIn </span>
                </div>
            </a>
            </li>
            <li>
            <OutboundLink
              className="is-hidden-desktop-only"
              href="https://facebook.com/hackrate"
              target="_blank"
            >
               <div className="is-vertical-center">
              <span className="icon">  <FaFacebookF size={18} color= {"#FFFFFF"}  /> </span>
              <span className="has-margin"> Facebook </span>
              </div>
            </OutboundLink>
            </li>
            <li>
            <OutboundLink
              className="is-hidden-desktop-only"
              href="https://twitter.com/hackrate"
              target="_blank"

            >
              <div className="is-vertical-center">
              <span className="icon">  <FaTwitter size={18} color= {"#FFFFFF"}/> </span>
              <span className="has-margin"> Twitter </span>
              </div>
            </OutboundLink>

            </li>
                   {/*  <li>
                      <OutboundLink
                        target="_blank"
                        href={`https://www.linkedin.com/company/hackrate`}
                      >
                        <img
                          role="presentation"
                          src="/img/site/icons/social/linkedin-icon-2.svg"
                          alt="linkedin-logo"
                        />{" "}
                        LinkedIn
                      </OutboundLink>
                    </li>
                    <li>
                      <OutboundLink
                        target="_blank"
                        href={`https://facebook.com/hackrate`}
                      >
                        <img
                          role="presentation"
                          src="/img/site/icons/social/facebook_logo.png"
                          alt="facebook-logo"
                        />{" "}
                        Facebook
                      </OutboundLink>
                    </li>
                    <li className="has-margin">
                      <a
                        className="twitter-follow-button"
                        href="https://twitter.com/hackrate"
                        data-size="large"
                        data-show-screen-name="false"
                      >
                        Follow @Hackrate
                      </a>
                    </li> */}
                    {/* <li className="has-margin">
                      <GitHubButton
                        href="https://github.com/Abhith"
                        data-size="large"
                        data-show-count="true"
                        aria-label="Follow @Abhith on GitHub"
                      >
                        Follow @Abhith
                      </GitHubButton>
                    </li>
                    <li className="has-margin">
                      <GitHubButton
                        href="https://github.com/Abhith/abhith.net"
                        data-icon="octicon-star"
                        data-size="large"
                        data-show-count="true"
                        aria-label="Star Abhith/abhith.net on GitHub"
                      >
                        Star
                      </GitHubButton>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
export default Footer;